import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Select, FormControl, MenuItem, InputLabel, Tabs, Tab, Box, IconButton, Tooltip, useMediaQuery, useTheme, Typography, AppBar, Toolbar } from '@mui/material';
import DatePicker from './_DatePicker';
import PrioritySelect from './_PrioritySelect';
import { useApiTasks } from './_fetchTasks';
import { useUser } from "@clerk/clerk-react";
import { commonStyles } from './_commonStyles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ContextDialogBox from './_contextDialogBox';
import AssigneeDialogBox from './_assigneeDialogBox';
import { getProjectPriority, getTaskDeadline, getTodayDate, getTaskPriority, calculateUrgency, calculateProjectPriorityScore, calculateTaskPriorityScore, calculateCompoundPriority } from './_prioritizerCompoundPriority';
import { ToggleButton } from '@mui/material';
import CreateEventDialog from './_createEventPropertiesDialog';
import useStore from '../../store/Store'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';

const LargeTextSection = ({ label, value, onChange, hintText }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [localValue, setLocalValue] = useState(value);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleExpandedChange = (e) => {
        setLocalValue(e.target.value);
    };

    const handleClose = () => {
        onChange(localValue);
        setIsExpanded(false);
    };

    const renderTextField = (fullScreen = false) => (
        <TextField
            label={label}
            value={fullScreen ? localValue : value}
            onChange={fullScreen ? handleExpandedChange : (e) => onChange(e.target.value)}
            multiline
            rows={fullScreen ? 15 : 4}
            fullWidth
            variant="outlined"
            onClick={() => isMobile && !isExpanded && setIsExpanded(true)}
            style={{ marginTop: '60px' }}
        />
    );

    const renderHint = () => (
        <Typography variant="body2" style={{ marginTop: '8px', color: theme.palette.text.secondary }}>
            {hintText.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                    {line}
                    <br />
                </React.Fragment>
            ))}
        </Typography>
    );

    if (isMobile && isExpanded) {
        return (
            <Dialog
                fullScreen
                open={isExpanded}
                onClose={handleClose}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Box sx={{ width: '60px' }} /> {/* Clearance for the app's main button */}
                        <Typography sx={{ flex: 1 }} variant="h6" component="div">
                            {label}
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    {renderTextField(true)}
                    <Typography variant="h6" style={{ marginTop: '16px', marginBottom: '8px' }}>Hint:</Typography>
                    {renderHint()}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <div>
            {renderTextField()}
            {isMobile ? (
                <IconButton onClick={() => setIsExpanded(true)}>
                    <HelpOutlineIcon />
                </IconButton>
            ) : (
                <Tooltip title={renderHint()} placement="right">
                    <IconButton>
                        <HelpOutlineIcon />
                    </IconButton>
                </Tooltip>
            )}
        </div>
    );
};

const TaskPropertiesDialog = ({ open, onClose, task: receivedTaskToProcess, onSave }) => {
    

    const { updateTaskAPI, createTask } = useApiTasks();                    // crud API
    const { addTask, updateTask } = useStore()        // CRUD STORE                                                                  // fetch contexts data from the server
    const { user } = useUser();                                                                                     // get clerk user information
    const [taskToProcess, setTaskToProcess] = useState(receivedTaskToProcess);
    const [taskName, setTaskName] = useState(taskToProcess ? taskToProcess.name : '');
    const [taskDeadline, setTaskDeadline] = useState(taskToProcess && taskToProcess.deadline ? taskToProcess.deadline : null);
    const [priority, setPriority] = useState(taskToProcess && taskToProcess.priority ? taskToProcess.priority : 'A');
    const [status, setStatus] = useState(taskToProcess && taskToProcess.status ? taskToProcess.status : 'Ns');
    const [context, setContext] = useState(taskToProcess && taskToProcess.context ? taskToProcess.context : null);
    const [assignee, setAssignee] = useState(taskToProcess && taskToProcess.assignee ? taskToProcess.assignee : null);
    const [parent, setParent] = useState(taskToProcess ? taskToProcess.parent : null);
    const [scheduleItDialogOpen, setScheduleItDialogOpen] = useState(false);                                         // schedule it dialog state declaration
    let formattedDeadline = null;


    if (taskDeadline !== null) {
        let deadlineDate = new Date(taskDeadline);                                                                  // transform the deadline to a Date object
        formattedDeadline = deadlineDate instanceof Date && !isNaN(deadlineDate)                                    // format the date
            ? `${deadlineDate.getFullYear()}-${("0" + (deadlineDate.getMonth() + 1)).slice(-2)}-${("0" + deadlineDate.getDate()).slice(-2)}`
            : '';
    }
    



    useEffect(() => {                                                                   // set the task to process when a new task is selected
        setTaskToProcess(receivedTaskToProcess);
    }, [receivedTaskToProcess]);


    const projectsData = useStore(state => state.projects);                  // get the tasks data from the store

    const getProjectName = (parentId) => {                                              // convert parent id to project name
        if (!parentId || !projectsData) {
            return "No parent project";
        }
        const project = projectsData.find((project) => project.id === parentId);
        return project ? project.project_name : "No parent project";
    };

    useEffect(() => {
        if (taskToProcess) {
            setTaskName(taskToProcess.name ? taskToProcess.name : '');
            setTaskDeadline(taskToProcess.deadline ? taskToProcess.deadline : null);
            setPriority(taskToProcess.priority ? taskToProcess.priority : 'A');
            setParent(taskToProcess.parent ? taskToProcess.parent : null);
            setStatus(taskToProcess.status ? taskToProcess.status : null);
            setContext(taskToProcess.context ? taskToProcess.context : null);
            setAssignee(taskToProcess.assignee ? taskToProcess.assignee : null);
            setIssueToBeAddressed(taskToProcess.issue_to_be_addressed ? taskToProcess.issue_to_be_addressed : '');
            setExpectedOutput(taskToProcess.expected_output ? taskToProcess.expected_output : '');
            setStrategy(taskToProcess.strategy ? taskToProcess.strategy : '');
        }
    }, [taskToProcess]);


    const [snackBarSuccessOpen, setSnackBarSuccessOpen] = useState(false);              // success and error snackbar states
    const [snackBarErrorOpen, setSnackBarErrorOpen] = useState(false);
    const [dialogMessageSnackBar, setDialogMessageSnackBar] = useState('');  // snackbar for dialog message display

    const handleSnackbarClose = (event, reason) => {                                    // snackbar close handler
        if (reason === 'clickaway') {
            console.log('clickaway');
            return;
        }
        setSnackBarSuccessOpen(false);
        setSnackBarErrorOpen(false);
    };


    const createFollowUpTaskTemplate = (completedTask) => {                             // create a follow-up task when a task is completed
        const followupTaskTemplate = {                                                                              // create a follow-up task template (retrieving the parent project)
            id: null,
            name: null,
            status: "Ns",
            parent: completedTask.parent,
            priority: "A",
        };
        setTaskToProcess(followupTaskTemplate);                                                                     // pass the follow-up task template to the TaskPropertiesDialog component
    };

    const handleSaveTask = async () => {
        if (formattedDeadline) {
            const deadlineDate = new Date(formattedDeadline);
            formattedDeadline = `${deadlineDate.getFullYear()}-${String(deadlineDate.getMonth() + 1).padStart(2, '0')}-${String(deadlineDate.getDate()).padStart(2, '0')}`;
        }
        
        const projectPriority = getProjectPriority(taskToProcess.parent, projectsData);
        const taskDeadline = getTaskDeadline(formattedDeadline);
        const todayDate = getTodayDate();
        const taskPriority = getTaskPriority(priority);
        const urgency = calculateUrgency(taskDeadline, todayDate);
        const projectPriorityScore = calculateProjectPriorityScore(projectPriority);
        const taskPriorityScore = calculateTaskPriorityScore(taskPriority);
        const compoundPriorityValue = calculateCompoundPriority(taskPriorityScore, projectPriorityScore, urgency);

        if (taskToProcess && taskToProcess.id === null) {
            // Handle follow-up task creation
            const followupTask = {
                name: taskName,
                priority: priority,
                status: status,
                deadline: formattedDeadline,
                parent: taskToProcess.parent,
                effort: 30,
                context: context,
                assignee: assignee,
                user: user.id,
                compound_priority: compoundPriorityValue,
                new_task: false,
                issue_to_be_addressed: issueToBeAddressed,
                expected_output: expectedOutput,
                strategy: strategy,
                scheduled: taskToProcess?.scheduled ?? false
            };

            if (followupTask.status === "Co") {
                createFollowUpTaskTemplate(followupTask);
            } else {
                onClose();
            }

            try {
                const response = await createTask(followupTask);
                if (response && response.task_id) {
                    const newTask = {
                        ...response,
                        id: response.task_id,
                        name: response.task_name,
                        scheduled: followupTask.scheduled
                    };
                    delete newTask.task_id;
                    addTask(newTask);
                } else {
                    setSnackBarErrorOpen(true);
                    setDialogMessageSnackBar("Failed to create task: No ID returned from the API");
                }
            } catch (error) {
                setSnackBarErrorOpen(true);
                setDialogMessageSnackBar("Failed to create task: " + error);
            }
        } else {
            // Handle task update
            const originalTask = useStore.getState().tasks.find(task => task.id === taskToProcess.id);
            const updatedTask = {
                id: taskToProcess.id,
                name: taskName,
                priority: priority,
                status: status,
                deadline: formattedDeadline,
                user: user.id,
                context: context,
                assignee: assignee,
                compound_priority: compoundPriorityValue,
                issue_to_be_addressed: issueToBeAddressed,
                expected_output: expectedOutput,
                strategy: strategy,
                scheduled: taskToProcess?.scheduled ?? originalTask?.scheduled ?? false
            };

            updateTask({ ...originalTask, ...updatedTask });
            try {
                await updateTaskAPI({
                    taskId: taskToProcess.id,
                    updatedTask: updatedTask
                });
                onSave(updatedTask);
            } catch (error) {
                setSnackBarErrorOpen(true);
                setDialogMessageSnackBar("Failed to update task: " + error);
                updateTask(originalTask);  // Revert to original task
            }
            
            if (typeof onClose === 'function') {
                onClose();
            }
        }
    };



    const handleScheduleIt = () => {                                                    // save button logic
        setScheduleItDialogOpen(true);
    };


    ////////////////////////////////////////////////////////////////////////// getting context data from the server and populating the dropdown //////////////////////////////////////////////////////////////////////////////
    const [contextToIdMapping, setContextToIdMapping] = useState({});                           // populating the context list dropdown
    const [contextArray, setContextArray] = useState(null);                                     // context array state declaration
    const fetchedContextsData = useStore(state => state.contexts);
    const isLoading = useStore(state => state.isLoading);           // get the isLoadingTasksFromApi from the store
    const errorLoading = useStore(state => state.error);             // get the errorLoadingTasksFromApi from the store 

    useEffect(() => {                                                                           // set context array when fetched data is available
        if (fetchedContextsData) {
            setContextArray(fetchedContextsData);
        }
    }, [fetchedContextsData]);

    useEffect(() => {                                                                           // set context to id mapping when context array is available
        if (contextArray) {
            let mapping = {};
            contextArray.forEach(context => {
                mapping[context.name] = context.id;
            });
            setContextToIdMapping(mapping);
        }
    }, [contextArray]);

    const contextIdToNameMapping = Object.fromEntries(                                          // context id to name mapping
        Object.entries(contextToIdMapping).map(([name, id]) => [id, name])
    );

    ////////////////////////////////////////////////////////////////////////// getting assignee data from the server and populating the dropdown //////////////////////////////////////////////////////////////////////////////
    const [assigneeToIdMapping, setAssigneeToIdMapping] = useState({});                           // populating the assignee list dropdown
    const [assigneeArray, setAssigneeArray] = useState(null);                                     // assignee array state declaration

    const fetchedAssigneesData = useStore(state => state.assignees);



    useEffect(() => {                                                                           // set assignee array when fetched data is available
        if (fetchedAssigneesData) {
            setAssigneeArray(fetchedAssigneesData);
        }
    }, [fetchedAssigneesData]);
    useEffect(() => {                                                                           // set assignee to id mapping when assignee array is available
        if (assigneeArray) {
            let mapping = {};
            assigneeArray.forEach(assignee => {
                mapping[assignee.name] = assignee.id;
            });
            setAssigneeToIdMapping(mapping);
        }
    }, [assigneeArray]);
    const assigneeIdToNameMapping = Object.fromEntries(                                          // assignee id to name mapping
        Object.entries(assigneeToIdMapping).map(([name, id]) => [id, name])
    );

    const [tabValue, setTabValue] = useState(0);
    const [issueToBeAddressed, setIssueToBeAddressed] = useState('');
    const [expectedOutput, setExpectedOutput] = useState('');
    const [strategy, setStrategy] = useState('');

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const issueHintText = `What challenge has to be addressed?
Who is concerned by that challenge?
When have I been aware of this challenge?
Where did this challenge occur?
How did this challenge come up?
Why is this a challenge?`;

    const outputHintText = `What specific results I want to achieve?
What tangible outcomes will indicate the challenge is resolved?
How much time do I allocate to see these results?
Who will benefit from these outcomes?
How will resolving this challenge impact my overall goals?`;

    const strategyHintText = `What specific steps?
Who do I need to help?
Are there any potential obstacles, and how will you overcome them?
What information do I need to acquire?`;

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
                <DialogTitle className="mb-4">
                    {taskToProcess && taskToProcess.id === null ? 'Create a follow-up task?' : 'Edit Task'}
                </DialogTitle>
                <DialogContent>
                    <Tabs value={tabValue} onChange={handleTabChange}>
                        <Tab label="General" />
                        <Tab label="High Performer" />
                    </Tabs>
                    <Box sx={{ p: 3 }}>
                        {tabValue === 0 && (
                            // Existing content for the General tab
                            <div className="space-y-4">
                                <TextField label="Name" value={taskName} onChange={e => setTaskName(e.target.value)} fullWidth />
                                <div className="flex flex-wrap space-y-4 sm:space-y-0 sm:space-x-4">
                                    <div className="mt-4 sm:mt-0">
                                        <DatePicker
                                            label="Due Date"
                                            value={formattedDeadline}
                                            onChange={(event) => {
                                                const selectedDate = new Date(event.target.value);
                                                setTaskDeadline(selectedDate);
                                            }}
                                            fullWidth
                                        />
                                    </div>
                                    <PrioritySelect
                                        label="Priority"
                                        value={priority}
                                        onChange={e => setPriority(e.target.value)}
                                    />
                                    <FormControl variant="outlined" sx={{ ...commonStyles, minWidth: 180 }}>
                                        <InputLabel htmlFor="status-select">Status</InputLabel>
                                        <Select
                                            id="status-select"
                                            label="Status"
                                            value={status || ''}
                                            onChange={e => setStatus(e.target.value)}
                                        >
                                            <MenuItem value="Co">Completed</MenuItem>
                                            <MenuItem value="Cn">Cancelled</MenuItem>
                                            <MenuItem value="De">Delegated</MenuItem>
                                            <MenuItem value="Ip">In Process</MenuItem>
                                            <MenuItem value="Ns">Not Started</MenuItem>
                                            <MenuItem value="Wa">Wait for</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="flex flex-wrap space-y-4 sm:space-y-0 sm:space-x-4">
                                    <FormControl className="w-full sm:w-1/4" variant="outlined" sx={{ ...commonStyles, minWidth: 180 }}>
                                        <InputLabel htmlFor="context-select">Context</InputLabel>
                                        <Select
                                            id="context-select"
                                            label="Context"
                                            value={contextIdToNameMapping[context] || ''}
                                            onChange={(event) => {
                                                const contextName = event.target.value;
                                                const contextId = contextToIdMapping[contextName];
                                                setContext(contextId);
                                            }}
                                        >
                                            {isLoading ? <MenuItem>Loading...</MenuItem> :
                                                errorLoading ? <MenuItem>Error</MenuItem> :
                                                    contextArray && contextArray.map((context) => (
                                                        <MenuItem key={context.id} value={context.name}>{context.name}</MenuItem>
                                                    ))
                                            }
                                            <ContextDialogBox
                                                setSelectedContext={setContext}
                                                setContextsList={setContextArray}
                                            />
                                        </Select>
                                    </FormControl>
                                    <FormControl className="w-full sm:w-1/4" variant="outlined" sx={{ ...commonStyles, minWidth: 180 }}>
                                        <InputLabel htmlFor="assignee-select">Assignee</InputLabel>
                                        <Select
                                            id="assignee-select"
                                            label="Assignee"
                                            value={assigneeIdToNameMapping[assignee] || ''}
                                            onChange={(event) => {
                                                const assigneeName = event.target.value;
                                                const assigneeId = assigneeToIdMapping[assigneeName];
                                                setAssignee(assigneeId);
                                            }}
                                        >
                                            {isLoading ? <MenuItem>Loading...</MenuItem> :
                                                errorLoading ? <MenuItem>Error</MenuItem> :
                                                    assigneeArray && assigneeArray.map((assignee) => (
                                                        <MenuItem key={assignee.id} value={assignee.name}>{assignee.name}</MenuItem>
                                                    ))
                                            }
                                            <AssigneeDialogBox
                                                setSelectedAssignee={setAssignee}
                                                setAssigneesList={setAssigneeArray}
                                            />
                                        </Select>
                                    </FormControl>
                                </div>
                                <TextField
                                    label="Parent"
                                    value={getProjectName(parent)}
                                    fullWidth
                                    disabled
                                />
                            </div>
                        )}
                        {tabValue === 1 && (
                            // New content for the High Performer tab
                            <div className="space-y-4">
                                <LargeTextSection 
                                    label="Issue to be addressed"
                                    value={issueToBeAddressed}
                                    onChange={setIssueToBeAddressed}
                                    hintText={issueHintText}
                                />
                                <LargeTextSection 
                                    label="Expected output"
                                    value={expectedOutput}
                                    onChange={setExpectedOutput}
                                    hintText={outputHintText}
                                />
                                <LargeTextSection 
                                    label="Strategy"
                                    value={strategy}
                                    onChange={setStrategy}
                                    hintText={strategyHintText}
                                />
                            </div>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <ToggleButton 
                            value="scheduleIt" 
                            style={{backgroundColor: '#00CA72', padding : '5px', borderRadius : '6px'}} 
                            onClick={handleScheduleIt}
                        >
                            Schedule it
                    </ToggleButton>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button onClick={handleSaveTask}>Save</Button>
                </DialogActions>
            </Dialog>
            <CreateEventDialog 
                scheduleItDialogOpen={scheduleItDialogOpen}             //sent true of false value to open or close the dialog
                setScheduleItDialogOpen={setScheduleItDialogOpen}       //received function to set the dialog open or close
                taskName={taskName}                                     //sent task name to be scheduled from taskToProcess
                formattedDeadline={formattedDeadline}                   //sent task deadline to be scheduled
                handleScheduleIt={handleScheduleIt}                     //received function to handle schedule it button
                user={user}                                             //sent user information
                taskToProcess={taskToProcess}                           //sent task data
                allDay={false}
            />
            <Snackbar open={snackBarSuccessOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    {taskToProcess && taskToProcess.id === null ? 'Follow up task has been created successfully' : 'Task has been updated successfully'}
                </Alert>
            </Snackbar>
            <Snackbar open={snackBarErrorOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                    {dialogMessageSnackBar}
                </Alert>
            </Snackbar>
        </>
    );
}

export default TaskPropertiesDialog;