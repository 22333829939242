import React, { useState, useEffect } from 'react';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import TaskPropertiesDialog from './_TaskPropertiesDialog';
import { FormControl, Select, MenuItem, TextField, IconButton } from '@mui/material';
import useStore from '../../store/Store';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useApiTasks } from './_fetchTasks';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

function formatDate(date) {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
}

function TasksTable({ tasks: receivedTasks, onTasksUpdate, showControls = false }) {
    const { updateTaskAPI } = useApiTasks();
    const updateTask = useStore(state => state.updateTask);
    const [tasks, setTasks] = useState(receivedTasks);
    const [editTaskDialogOpen, setEditTaskDialogOpen] = useState(false);
    const [taskToEdit, setTaskToEdit] = useState(null);
    const [selectedContext, setSelectedContext] = useState('all');
    const [searchQuery, setSearchQuery] = useState('');
    const [showScheduled, setShowScheduled] = useState(false);
    const contextsData = useStore(state => state.contexts);

    useEffect(() => {
        setTasks(receivedTasks);
    }, [receivedTasks]);

    const handleEditTask = (task) => {
        setTaskToEdit(task);
        setEditTaskDialogOpen(true);
    };

    const handleSaveTask = (updatedTask) => {
        if (typeof onTasksUpdate === 'function') {
            onTasksUpdate(updatedTask);
        }
        const taskIndex = tasks.findIndex(task => task.id === updatedTask.id);

        let newTasks;
        if (taskIndex !== -1) {
            newTasks = [...tasks];
            newTasks[taskIndex] = updatedTask;
        } else {
            newTasks = [...tasks, updatedTask];
        }

        const filteredTasks = newTasks.filter(task => task.status !== 'Cn' && task.status !== 'Co');
        setTasks(filteredTasks);
    };

    const handleContextChange = (event) => {
        setSelectedContext(event.target.value);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const toggleScheduled = async (task) => {
        const updatedTask = { 
            ...task, 
            scheduled: !task.scheduled 
        };

        // Optimistic update for local state
        const taskIndex = tasks.findIndex(t => t.id === task.id);
        if (taskIndex !== -1) {
            const newTasks = [...tasks];
            newTasks[taskIndex] = updatedTask;
            setTasks(newTasks);
        }

        try {
            // Update API
            await updateTaskAPI({ 
                taskId: task.id, 
                updatedTask: updatedTask // Send full task object
            });
            
            // Update global store
            updateTask(updatedTask);
            
            // Notify parent component
            if (typeof onTasksUpdate === 'function') {
                onTasksUpdate(updatedTask);
            }
        } catch (error) {
            // Revert on error
            const revertedTasks = [...tasks];
            if (taskIndex !== -1) {
                revertedTasks[taskIndex] = task;
                setTasks(revertedTasks);
                updateTask(task); // Revert store as well
            }
            console.error('Failed to update task scheduled status:', error);
        }
    };

    const resetAllScheduled = async () => {
        // Get all active tasks (not completed or cancelled)
        const activeTasks = tasks.filter(task => task.status !== 'Co' && task.status !== 'Cn');
        
        // Create updated tasks array with scheduled set to false
        const updatedTasks = activeTasks.map(task => ({
            ...task,
            scheduled: false
        }));

        try {
            // Update each task in the backend
            await Promise.all(updatedTasks.map(task => 
                updateTaskAPI({ 
                    taskId: task.id, 
                    updatedTask: task 
                })
            ));

            // Update local state
            setTasks(prevTasks => 
                prevTasks.map(task => 
                    task.status !== 'Co' && task.status !== 'Cn' 
                        ? { ...task, scheduled: false }
                        : task
                )
            );

            // Update store for each task
            updatedTasks.forEach(task => {
                updateTask(task);
            });

            // Notify parent if needed
            if (typeof onTasksUpdate === 'function') {
                updatedTasks.forEach(task => {
                    onTasksUpdate(task);
                });
            }
        } catch (error) {
            console.error('Failed to reset scheduled tasks:', error);
            // Revert changes if needed
        }
    };

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const defaultDate = new Date(1970, 0, 1);
    defaultDate.setHours(0, 0, 0, 0);

    const filteredTasks = tasks && [...tasks]
        .filter(task => task !== null)
        .filter(task => {
            const matchesContext = selectedContext === 'all' || task.context === selectedContext;
            const matchesSearch = task.name.toLowerCase().includes(searchQuery.toLowerCase());
            const matchesScheduledState = showScheduled ? true : !task.scheduled;
            return matchesContext && matchesSearch && matchesScheduledState;
        })
        .sort((a, b) => (b.compound_priority || 0) - (a.compound_priority || 0));

    return (
        <>
            {showControls && (
                <div className="flex flex-col space-y-4 mb-4 mt-2">
                    <div className="flex justify-between items-center">
                        <FormControl className="min-w-[200px]">
                            <Select
                                value={selectedContext}
                                onChange={handleContextChange}
                                aria-label="Filter tasks by context"
                            >
                                <MenuItem value="all">All Contexts</MenuItem>
                                {contextsData.map(context => (
                                    <MenuItem key={context.id} value={context.id}>
                                        {context.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            placeholder="Search tasks..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                            aria-label="Search tasks"
                        />
                        <div className="flex space-x-2">
                            <Tooltip title={showScheduled ? "Hide scheduled tasks" : "Show scheduled tasks"}>
                                <IconButton 
                                    onClick={() => setShowScheduled(!showScheduled)}
                                    aria-label={showScheduled ? "Hide scheduled tasks" : "Show scheduled tasks"}
                                >
                                    {showScheduled ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                </IconButton>
                            </Tooltip>
                            
                            <Tooltip title="Set all scheduled tasks to unscheduled">
                                <IconButton 
                                    onClick={resetAllScheduled}
                                    aria-label="Reset all scheduled tasks"
                                >
                                    <RestartAltIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            )}

            <table 
                id="external-events" 
                className="table-auto bg-gray-800 text-white my-4 rounded shadow-lg" 
                style={{ width: '100%' }}
                role="grid"
                aria-label="Tasks list"
            >
                <tbody>
                    {filteredTasks && filteredTasks.map((task) => {
                        const taskDeadline = new Date(task.deadline);
                        taskDeadline.setHours(0, 0, 0, 0);

                        let chipLabel = '';
                        let chipColor = '';

                        if (taskDeadline.getTime() !== defaultDate.getTime()) {
                            if (taskDeadline < today) {
                                chipLabel = 'overdue';
                                chipColor = 'error';
                            } else if (taskDeadline.getTime() === today.getTime()) {
                                chipLabel = 'today';
                                chipColor = 'warning';
                            } else if (taskDeadline.getTime() === tomorrow.getTime()) {
                                chipLabel = 'tomorrow';
                                chipColor = 'primary';
                            }
                        }

                        return (
                            <tr 
                                key={task.id} 
                                data-title={task.name} 
                                data-id={task.id} 
                                data-effort={task.effort} 
                                className="fc-event draggable bg-gray-700 hover:bg-gray-600 cursor-move" 
                                draggable="true" 
                                onClick={() => handleEditTask(task)}
                                role="row"
                                aria-label={`Task: ${task.name}`}
                            >
                                <td className="border-gray-700 border p-2 flex justify-between items-center">
                                    <div className="flex items-center">
                                        {showControls && (
                                            <IconButton
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    toggleScheduled(task);
                                                }}
                                                aria-label={task.scheduled ? "Mark as unscheduled" : "Mark as scheduled"}
                                            >
                                                <EventAvailableIcon 
                                                    sx={{ 
                                                        color: task.scheduled ? '#00CA72' : '#808080'  // Green when scheduled, grey when not
                                                    }} 
                                                />
                                            </IconButton>
                                        )}
                                        <Tooltip 
                                            title={
                                                <div style={{ fontSize: '1.25em' }}>
                                                    Compound Priority: {task.compound_priority}<br/>
                                                    Due Date: {taskDeadline.getTime() === defaultDate.getTime() ? 'none' : formatDate(taskDeadline)}
                                                </div>
                                            }
                                            aria-label={`Priority: ${task.compound_priority}, Due: ${taskDeadline.getTime() === defaultDate.getTime() ? 'none' : formatDate(taskDeadline)}`}
                                        >
                                            <span role="button" tabIndex={0}>{task.name}</span>
                                        </Tooltip>
                                        {chipLabel && 
                                            <Chip 
                                                className="ml-2" 
                                                label={chipLabel} 
                                                color={chipColor} 
                                                size="small"
                                                role="status"
                                                aria-label={`Task is ${chipLabel}`}
                                            />
                                        }
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            
            <TaskPropertiesDialog
                open={editTaskDialogOpen}
                onClose={() => setEditTaskDialogOpen(false)}
                task={taskToEdit}
                onSave={handleSaveTask}
                aria-label="Edit task properties"
            />
        </>
    );
}

export default TasksTable;