import { useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { Draggable } from '@fullcalendar/interaction';
import { useApiEvents } from './subComponents/_fetchEvents';
import BaseLayout from './baselayout';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import TasksTable from './subComponents/_calendarTasksTable';
import EventsCalendar from './subComponents/_CalendarEventsCalendar';
import CircularProgress from '@mui/material/CircularProgress';
import SyncIcon from '@mui/icons-material/Sync';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import Tooltip from '@mui/material/Tooltip';
import LinkIcon from '@mui/icons-material/Link';
import { useTheme, ToggleButton, ToggleButtonGroup } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useUser, useAuth } from "@clerk/clerk-react";

import Alert from '@mui/material/Alert';

import useStore from '../store/Store'
import Snackbar from "@mui/material/Snackbar";




function Calendar() {
    const headerContent = "Calendar";                                                       // set the header content 
    const { getToken } = useAuth();                                                         // get the user token from clerk auth
    const { user } = useUser();                                                             // get clerk user information
    const { createEvent } = useApiEvents();                                                 // fetch the events data from the API
    const { addEvent } = useStore()   

    /////////////////////////////////////////////////////////////////   tasks management /////////////////////////////////////////////////////////////////



    const isLoadingTasks = useStore(state => state.isLoading);       // get the isLoadingTasksFromApi from the store
    const errorLoadingTasks = useStore(state => state.error);             // get the errorLoadingTasksFromApi from the store
    const [openSnackbar, setOpenSnackbar] = useState(false);                // snackbar for error display
    const [dialogMessageSnackBar, setDialogMessageSnackBar] = useState('');  // snackbar for dialog message display

    const [errorDialogOpen, setErrorDialogOpen] = useState(false);                          // error dialog in case of a follow-up task with deleted task parent
    const [localTasksDataset, setLocalTasksDataset] = useState([]);                         // task local dataset

    const storeTasksData = useStore(state => state.tasks);
    
    useEffect(() => {
        const filteredTasks = storeTasksData.filter(task => task.status !== 'Cn' && task.status !== 'Co');
        setLocalTasksDataset(filteredTasks);
    }, [storeTasksData]);





    useEffect(() => {                                                                       // make the tasks rows draggable
        let draggableEl = document.getElementById("external-events");
        if (draggableEl) {
            let draggable = new Draggable(draggableEl, {                                  
                itemSelector: '.fc-event',
                eventData: function(eventEl) {
                    let title = eventEl.getAttribute('title');              
                    let id = eventEl.getAttribute('data-id');
                    let effort = eventEl.getAttribute('data-effort');
                    return {
                        title: title,
                        id: id,
                        effort: effort,
                        allDay: true
                    };
                }
            });

            return () => {
                draggable.destroy();
            };
        }
    }, [localTasksDataset]);
    




    ///////////////////////////////////////////////////////////////////////   Events management /////////////////////////////////////////////////////////////////


    const eventsDataset = useStore(state => state.events);



    const handleDrop = async (info) => {
        const draggedEl = info.draggedEl;                           // get the dragged element
        const id = draggedEl.getAttribute('data-id');               // get the id of the task
        const title = draggedEl.getAttribute('data-title');              // get the title of the task
        let effort = Number(draggedEl.getAttribute('data-effort')); // convert the attribute to a number
        effort = effort === 0 ? 30 : effort; // if it's 0, set it to 30
        const duration = effort * 60 * 1000; // event duration in milliseconds
        const end = new Date(info.date.getTime() + duration);       // calculate the end time of the event

        const newEventSentToApi = {                                          // convert the event for the API
            event_title: title,
            event_start: new Date(info.date.getTime()), 
            event_end: new Date(end.getTime()),
            event_allDay: info.allDay,
            user: user.id,
            event_taskId: id
        };
        try {
            const response = await createEvent(newEventSentToApi);
            if (response && response.id) {
                const newEvent = {
                    id: response.id,
                    event_title: response.event_title, 
                    event_start: response.event_start,
                    event_end: response.event_end,
                    event_allDay: response.event_allDay,
                    event_taskId: response.event_taskId,
                    complete: false,
                    user: user.id,
                };
                addEvent(newEvent);


            } else {
                setOpenSnackbar(true);
                setDialogMessageSnackBar("Failed to create task: No ID returned from the API");
            }

        } catch (error) {
            setOpenSnackbar(true);
            setDialogMessageSnackBar("Failed to create task: " + error);
        }                      // send the event to the API
    };




/////////////////////////////////////////////////////////////////////   Google Calendar /////////////////////////////////////////////////////////////////
const queryClient = useQueryClient();                           // used to refetch the events data after an update
    const clientId = process.env.REACT_APP_CLIENT_ID;               // get the google client id from the environment
    const redirectUri = process.env.REACT_APP_REDIRECT_URI;         // get the redirect uri from the environment

    // button link to connect to google calendar
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&response_type=code&scope=https://www.googleapis.com/auth/calendar&redirect_uri=${redirectUri}&access_type=offline&prompt=consent`;
    const handleAuthRedirect = () => {
        window.location.href = authUrl;
    };
    
    const [isConnected, setIsConnected] = useState(false);          // confirm if the user is connected to google calendar
    const [isSyncing, setIsSyncing] = useState(false);              // confirm if the user is syncing google calendar
    const [syncStatus, setSyncStatus] = useState({
        status: '',
        created_events: 0,
        updated_events: 0,
        deleted_events: 0,
    });
    const [isSyncDialogOpen, setIsSyncDialogOpen] = useState(false);
    const [isUnlinkDialogOpen, setIsUnlinkDialogOpen] = useState(false);



    useEffect(() => {
        const fetchIsConnected = async () => {
            if (!isUnlinkDialogOpen && user.id) { // Only run the effect if isUnlinkDialogOpen is false
                const token = await getToken();
                fetch(`${process.env.REACT_APP_API_URL}/IsConnectedToGoogleApiView?userId=${user.id}`, { 
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                })
                .then(response => response.json())
                .then(data => {
                    setIsConnected(data.is_connected);
                })
                .catch(error => console.error('Error:', error));
            }
        };
    
        fetchIsConnected();
    }, [isUnlinkDialogOpen, getToken, user.id]);

    
    const handleUnlink = () => {
        // Make a request to your Django server to remove the user's Google Calendar link
        const url = `${process.env.REACT_APP_API_URL}/unlink-google-calendar/`;
        const token = localStorage.getItem('token'); // Get the user's token

        fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            setIsUnlinkDialogOpen(true);
            setTimeout(() => {
                setIsUnlinkDialogOpen(false);
            }, 2000); // 2000 milliseconds = 2 seconds
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    };
    
    const syncGoogleCalendar = async () => {
        if (isConnected) {
            setIsSyncing(true);
            setIsSyncDialogOpen(true);

            if (user.id) {
                const token = await getToken();
                fetch(`${process.env.REACT_APP_API_URL}/sync-google-calendar?userId=${user.id}`, { 
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                })
                .then(response => response.json())
                .then(data => {
                    setSyncStatus(data);  // Set the sync status with the response from the backend
                    setIsSyncing(false);
                    setTimeout(() => {
                        setIsSyncDialogOpen(false);
                    }, 2000);
                })
                .catch(error => {
                    console.error('Error:', error);
                    setIsSyncing(false);
                    setIsSyncDialogOpen(false);
                });
            }
        }
    };

    // useEffect(() => {                                               // trigger the sync function when the user is connected
    //     syncGoogleCalendar();
    // }, [isConnected, queryClient]);

    useEffect(() => {                                               // refetch the events data after syncing with google calendar
        if (!isSyncing) {
            queryClient.invalidateQueries('fetchedEventsData');
        }
    }, [isSyncing, queryClient]);


    const theme = useTheme();
    const isNarrowScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [selectedDiv, setSelectedDiv] = useState('events');

    useEffect(() => {
        console.log('isNarrowScreen: ', isNarrowScreen);       // log the screen width
    }, [isNarrowScreen]);

    return (
        <BaseLayout headerContent={headerContent}>
            <div className="w-full h-full p-4" role="main" aria-label="Calendar View">
            {isNarrowScreen && (
                <ToggleButtonGroup
                    value={selectedDiv}
                    exclusive
                    onChange={(event, newSelection) => setSelectedDiv(newSelection)}
                    className="absolute top-4 left-2/3 transform -translate-x-2/3 sm:left-1/3 sm:-translate-x-1/3 z-50"
                    aria-label="Toggle between tasks and events view"
                >
                    {selectedDiv === 'tasks' && 
                        <ToggleButton 
                            value="events" 
                            style={{backgroundColor: '#00CA72', padding : '5px', borderRadius : '6px'}}
                            aria-label="Switch to events view"
                        >
                            Events
                        </ToggleButton>
                    }
                    {selectedDiv === 'events' && 
                        <ToggleButton 
                            value="tasks" 
                            style={{backgroundColor: '#00CA72', padding : '5px', borderRadius : '6px'}}
                            aria-label="Switch to tasks view"
                        >
                            Tasks
                        </ToggleButton>
                    }
                </ToggleButtonGroup>
            )}
            {(isLoadingTasks) ? (
                <div className="flex items-center justify-center min-h-screen" role="status" aria-label="Loading tasks">
                    <CircularProgress aria-label="Loading spinner" />
                </div>
            ) : errorLoadingTasks ? (
                <Dialog open={true} aria-labelledby="error-dialog-title">
                    <DialogTitle id="error-dialog-title">Error</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {'An error has occurred: ' + errorLoadingTasks.message}
                        </DialogContentText>
                    </DialogContent>    
                    <DialogActions>
                        <Button color="primary" aria-label="Close error dialog">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            ) : (
                <div className="flex flex-row justify-center md:space-x-4 max-w-screen-xl mx-auto" role="region" aria-label="Calendar content">
                    {(selectedDiv === 'tasks' || !isNarrowScreen) && (
                        <div className="flex-grow" style={{ maxWidth: !isNarrowScreen ? '50%' : '100%' }} role="region" aria-label="Tasks section">
                            <div role="toolbar" aria-label="Google Calendar controls">
                                {isConnected ? (
                                    <div className="flex items-center space-x-10">
                                        <Tooltip title="Connected to Google Calendar">
                                            <LinkIcon color="success" className="mx-2.5" aria-label="Connected to Google Calendar" />
                                        </Tooltip>
                                        <Tooltip title="Sync">
                                            <Button onClick={syncGoogleCalendar} aria-label="Sync with Google Calendar">
                                                <SyncIcon />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="Unlink Google Calendar">
                                            <Button onClick={handleUnlink} aria-label="Unlink Google Calendar">
                                                <LinkOffIcon />
                                            </Button>
                                        </Tooltip>
                                    </div>
                                ) : (
                                    <Tooltip title="Link Your Google Calendar">
                                        <Button onClick={handleAuthRedirect} aria-label="Link Google Calendar">
                                            <LinkIcon />
                                        </Button>
                                    </Tooltip>
                                )}
                            </div>
                            <div className="sticky top-0 z-10 bg-gray-800 text-white p-2" role="heading" aria-level="2">
                                Task Names
                            </div>
                            <div 
                                className="flex-grow" 
                                style={{ 
                                    height: '70vh', 
                                    maxHeight: '100%', 
                                    overflow: 'auto',
                                    msOverflowStyle: 'none',  // Hide scrollbar in IE/Edge
                                    scrollbarWidth: 'none',   // Hide scrollbar in Firefox
                                }} 
                                role="region" 
                                aria-label="Tasks list"
                            >
                                <style jsx global>{`
                                    /* Hide scrollbar for Chrome, Safari and Opera */
                                    div::-webkit-scrollbar {
                                        display: none;
                                    }
                                `}</style>
                                <TasksTable 
                                    tasks={localTasksDataset}
                                    aria-label="Tasks table"
                                    showControls={true}
                                />
                            </div>
                        </div>
                    )}

                    {(selectedDiv === 'events' || !isNarrowScreen) && (
                        <div 
                            className="flex-grow" 
                            style={{ 
                                height: '80vh', 
                                maxHeight: '100%',
                                msOverflowStyle: 'none',
                                scrollbarWidth: 'none',
                            }} 
                            role="region" 
                            aria-label="Events calendar"
                        >
                            <style jsx global>{`
                                div::-webkit-scrollbar {
                                    display: none;
                                }
                            `}</style>
                            <EventsCalendar 
                                events={eventsDataset}
                                handleDrop={handleDrop} 
                                openAsFullScreen={false}
                                aria-label="Events calendar"
                            />
                        </div>
                    )}
                </div>
            )}
            
            <Dialog open={isSyncDialogOpen} aria-labelledby="sync-dialog-title">
                <DialogTitle id="sync-dialog-title">Syncing...</DialogTitle>
                <DialogContent className="flex items-center justify-center h-24">
                    {isSyncing ? (
                        <CircularProgress aria-label="Syncing in progress" />
                    ) : (
                        syncStatus && (
                            <div role="status" aria-label="Sync results">
                                <p>Created events: {syncStatus.created_events}</p>
                                <p>Updated events: {syncStatus.updated_events}</p>
                                <p>Deleted events: {syncStatus.deleted_events}</p>
                            </div>
                        )
                    )}
                </DialogContent>
            </Dialog>

            <Dialog open={isUnlinkDialogOpen} aria-labelledby="unlink-dialog-title">
                <DialogTitle id="unlink-dialog-title">Unlinking...</DialogTitle>
                <DialogContent className="flex items-center justify-center h-24">
                    <p>Successfully Unlinked with Google Calendar</p>
                </DialogContent>
            </Dialog>

            <Dialog 
                open={errorDialogOpen} 
                onClose={() => setErrorDialogOpen(false)}
                aria-labelledby="error-task-dialog-title"
            >
                <DialogTitle id="error-task-dialog-title">
                    This task has been deleted from the task list. No follow-up task can be added. Add a task via Next Task Capture or Manage your tasks.
                </DialogTitle>
            </Dialog>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
                role="alert"
            >
                <Alert
                    onClose={() => setOpenSnackbar(false)}
                    severity="error"
                    sx={{ width: "100%" }}
                >
                    {dialogMessageSnackBar}
                </Alert>
            </Snackbar>
            </div>
        </BaseLayout>
    );
    }
    export default Calendar;