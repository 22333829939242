import React, { useState, useEffect, useContext } from 'react';
import { useApiTasks } from './_fetchTasks';
import { SelectedRowsContext } from './_prioritizerSelectedRowsContext';
import { Select, MenuItem, TextField, Fab, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { isValid, parseISO, format } from 'date-fns';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import AddIcon from '@mui/icons-material/Add';
import { getProjectPriority, getTaskDeadline, getTodayDate, getTaskPriority, calculateUrgency, calculateProjectPriorityScore, calculateTaskPriorityScore, calculateCompoundPriority } from './_prioritizerCompoundPriority';
import { useUser } from "@clerk/clerk-react";
import useStore from '../../store/Store'
import Snackbar from "@mui/material/Snackbar";


const previousCompoundPriorityValues = {};

const TasksPrioritizer = ({ columns, onDataLengthChange  }) => {
    
    const isLoadingTasksFromApi = useStore(state => state.isLoading);       // get the isLoadingTasksFromApi from the store
    const errorLoadingFromApi = useStore(state => state.error);             // get the errorLoadingTasksFromApi from the store
    const [openSnackbar, setOpenSnackbar] = useState(false);                // snackbar for error display
    const [dialogMessageSnackBar, setDialogMessageSnackBar] = useState('');  // snackbar for dialog message display


    const localTasksData = useStore(state => state.tasks);                  // get the tasks data from the store
    const { deleteTasksAPI, updateTaskAPI, createTask  } = useApiTasks();   // CRUD API
    const { addTask, updateTask, removeTask, setTasks } = useStore()        // CRUD STORE

    const { user } = useUser();                         // get clerk user information

    //////////////////////////////////////////////////////////////  // TASKS API COMMUNICATION //  //////////////////////////////////////////////////////////////

    const [parentProjectToFilterWith, ] = useContext(SelectedRowsContext);              // get the selected project IDs from the context
    const [filteredTasksDataDisplayed, setFilteredTasksDataDisplayed] = useState([]);   // filtering tasks based on selected project
    useEffect(() => {                                                                   // update the filteredTasksDataWithParent when the projectSelectedRows or tasksData change                                      
        const unfilteredLocalTasks = localTasksData || [];
        const filteredTasksDataWithParent = parentProjectToFilterWith.length > 0 ? 
            unfilteredLocalTasks.filter(task => parentProjectToFilterWith.includes(task.parent)) : unfilteredLocalTasks;
        setFilteredTasksDataDisplayed(filteredTasksDataWithParent);
    }, [parentProjectToFilterWith, localTasksData]);

    const updateTaskAttributes = async (params, field, value) => {
        const originalTask = useStore.getState().tasks.find(task => task.id === params.id);
        const taskToUpdate = filteredTasksDataDisplayed.find((task) => task.id === params.id);
        if (taskToUpdate) {
            taskToUpdate[field] = value;
            updateTask({ 
                ...originalTask, 
                ...taskToUpdate, 
                scheduled: taskToUpdate.scheduled || false 
            });
            try {
                await updateTaskAPI({ taskId: params.id, updatedTask: taskToUpdate });
            } catch (error) {
                setOpenSnackbar(true);
                setDialogMessageSnackBar("Failed to update task: " + error);
                updateTask(originalTask);
            }
        }
    };
    const handleDelete = async () => {                              // Function to handle the delete button click
        const originalTasks = useStore.getState().tasks;
        removeTask(selectedRows);
        try {
            await deleteTasksAPI(selectedRows);
        } catch (error) {
            setOpenSnackbar(true);
            setDialogMessageSnackBar("Failed to delete task: " + error);
            setTasks(originalTasks);  // Restore the original tasks

        }
        setSelectedRows([]);
        setOpen(false);

    };
    const handleSubmit = async () => {
        const newTaskSentToApi = {
            name: newTaskName,
            priority: 'A',
            parent: parentProjectToFilterWith[0],
            effort: 30,
            user: user.id,
            new_task: false,
            assignee: null,
            complete: false,
            context: null,
            deadline: null,
            status: "Ns",

        };
        try {
            const response = await createTask(newTaskSentToApi);
            if (response && response.task_id) {
                const newTask = {
                    ...response,
                    id: response.task_id,
                    name: response.task_name
                };
                delete newTask.task_id;

                addTask(newTask);

            } else {
                setOpenSnackbar(true);
                setDialogMessageSnackBar("Failed to create task: No ID returned from the API");
            }

        } catch (error) {
            setOpenSnackbar(true);
            setDialogMessageSnackBar("Failed to create task: " + error);
        }

        setOpenAdd(false);
        setName(''); // reset name
    };

    //////////////////////////////////////////////////////////////  // PROJECTS API COMMUNICATION //  //////////////////////////////////////////////////////////////
    const projectsData = useStore(state => state.projects);

    const getProjectName = (parentId) => {                          // Function translate the project id to the project name
        const project = projectsData.find((project) => project.id === parentId);
        return project ? project.project_name : '';
    };



    ///////////////////////////////////////////////////////////////////////  // DIALOG BOX //  ///////////////////////////////////////////////////////////////////////
    const [open, setOpen] = React.useState(false);                  // delete dialog states
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    //////////////////////////////////////////////////////////////  // CONTEXTS AND ASSIGNEES API COMMUNICATION //  //////////////////////////////////////////////////////////////
    const [contextToIdMapping, setContextToIdMapping] = useState({});   // populating the context list dropdown
    
    const contextsData = useStore(state => state.contexts);

    useEffect(() => {                                                   // load the context list once the contextsData is fetched
        if (contextsData) {
            let mapping = {};
            contextsData.forEach(context => {
                mapping[context.name] = context.id;
            });
            setContextToIdMapping(mapping);
        }
    }, [contextsData]);

    const assigneesData = useStore(state => state.assignees);

    const [assigneeToIdMapping, setAssigneeToIdMapping] = useState({}); // populating the assignee list dropdown

    useEffect(() => {                                                   // fetch the assignee list once the assigneesData is fetched
        if (assigneesData) {
            let mapping = {};
            assigneesData.forEach(assignee => {
                mapping[assignee.name] = assignee.id;
            });
            setAssigneeToIdMapping(mapping);
        }
    }, [assigneesData]);

    ///////////////////////////////////////////////////////////////////////  // task table columns definition//  ///////////////////////////////////////////////////////////////////////
    const [selectedRows, setSelectedRows] = useState([]);               // Initialize tasks selectedRows with an empty array
    
    const taskColumns = [                                               // Define the columns for the task table
        // name
        {
            field: 'name',
            headerName: 'Name',
            width: 250,
            renderCell: (params) => (
                <Tooltip title={params.value ? params.value.toString() : ''} enterDelay={500}>
                    <div>
                        <TextField
                            sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }}}
                            defaultValue={params.value}
                            onKeyDown={(event) => {
                                if (event.key === ' ') {
                                    event.stopPropagation();
                                }
                                if (event.key === 'a' && event.ctrlKey) {
                                    event.stopPropagation();
                                }
                            }}
                            onBlur={(event) => updateTaskAttributes(params, 'name', event.target.value)}
                        />
                    </div>
                </Tooltip>
            ),
        },
        // priority dropdown
        { 
            field: 'priority', 
            headerName: 'Priority', 
            width: 80,
            renderCell: (params) => (
                <Select
                    sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }}}
                    value={params.value || ''}
                    onChange={(event) => updateTaskAttributes(params, 'priority', event.target.value)}
                >
                    <MenuItem value="A">A</MenuItem>
                    <MenuItem value="B">B</MenuItem>
                    <MenuItem value="C">C</MenuItem>
                    <MenuItem value="D">D</MenuItem>
                </Select>
            ),
        },
        {
            field: 'compoundPriority',
            headerName: 'Priority Score',
            width: 130,
            valueGetter: (params) => {
                // list the whole attribute lists of params
                const projectPriority = getProjectPriority(params.row.parent, projectsData);
                const taskDeadline = getTaskDeadline(params.row.deadline);
                const todayDate = getTodayDate();
                const taskPriority = getTaskPriority(params.row.priority);
                const urgency = calculateUrgency(taskDeadline, todayDate);
                const projectPriorityScore = calculateProjectPriorityScore(projectPriority);
                const taskPriorityScore = calculateTaskPriorityScore(taskPriority);
                const newCompoundPriority = calculateCompoundPriority(taskPriorityScore, projectPriorityScore, urgency);

                // If the value has changed, log it and update the previous value
                if (previousCompoundPriorityValues[params.row.id] !== newCompoundPriority) {
                    updateTaskAttributes(params.row, 'compound_priority', newCompoundPriority);
                    previousCompoundPriorityValues[params.row.id] = newCompoundPriority;
                }

                return newCompoundPriority;
            },
            renderCell: (params) => (
                <Tooltip title={params.value ? params.value.toString() : ''} enterDelay={500}>
                    <div>
                        {params.value}
                    </div>
                </Tooltip>
            ),
        },
        // deadline date picker
        { 
            field: 'deadline', 
            headerName: 'Deadline', 
            width: 200,
            renderCell: (params) => {
                let date = params.value ? parseISO(params.value) : null;
                return (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }}}
                            value={date && isValid(date) ? date : null}
                            onChange={(newValue) => updateTaskAttributes(params, 'deadline', newValue ? format(newValue, 'yyyy-MM-dd') : '')}
                            inputFormat="dd/MM/yy"
                            format="dd/MM/yy"
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                );
            },
        },
        // status dropdown
        { 
            field: 'status', 
            headerName: 'Status', 
            width: 150,
            renderCell: (params) => (
                <Select
                    sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }}}
                    value={params.value || ''}
                    onChange={(event) => updateTaskAttributes(params, 'status', event.target.value)}
                >
                    <MenuItem value="Co">Completed</MenuItem>
                    <MenuItem value="Cn">Cancelled</MenuItem>
                    <MenuItem value="De">Delegated</MenuItem>
                    <MenuItem value="Ip">In Process</MenuItem>
                    <MenuItem value="Ns">Not Started</MenuItem>
                    <MenuItem value="Wa">Wait for</MenuItem>
                </Select>
            ),
        },
        // effort text field
        {
            field: 'effort',
            headerName: 'Effort',
            width: 130,
            renderCell: (params) => (
                <TextField
                    type="number"
                    sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }}}
                    defaultValue={params.value}
                    onKeyDown={(event) => {
                        if (event.key === ' ') {
                            event.stopPropagation();
                        }
                        if (event.key === 'a' && event.ctrlKey) {
                            event.stopPropagation();
                        }
                    }}
                    onBlur={(event) => updateTaskAttributes(params, 'effort', parseInt(event.target.value))}
                />
            ),
        },
        // context dropdown
        {
            field: 'context',
            headerName: 'Context',
            width: 130,
            renderCell: (params) => {
                const contextIdToNameMapping = Object.fromEntries(
                    Object.entries(contextToIdMapping).map(([name, id]) => [id, name])
                );
                return (
                    <Select
                        sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }}}
                        value={contextIdToNameMapping[params.value] || ''}
                        onChange={(event) => {
                            const contextName = event.target.value;
                            const contextId = contextToIdMapping[contextName];
                            updateTaskAttributes(params, 'context', contextId);
                        }}
                    >
                        {/* {isLoadingContexts ? <MenuItem>Loading...</MenuItem> : 
                            errorLoadingContexts ? <MenuItem>Error</MenuItem> : */}
                            {contextsData.map((context) => (
                                <MenuItem key={context.id} value={context.name}>{context.name}</MenuItem>

                            ))
                        }
                    </Select>
                );
            },
        },
        // assignee dropdown
        {
            field: 'assignee',
            headerName: 'Assignee',
            width: 130,
            renderCell: (params) => {
                const assigneeIdToNameMapping = Object.fromEntries(
                    Object.entries(assigneeToIdMapping).map(([name, id]) => [id, name])
                );
                return (
                    <Select
                        sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }}}
                        value={assigneeIdToNameMapping[params.value] || ''}
                        onChange={(event) => {
                            const assigneeName = event.target.value;
                            const assigneeId = assigneeToIdMapping[assigneeName];
                            updateTaskAttributes(params, 'assignee', assigneeId);
                        }}
                    >
                        {/* {isLoadingAssignees ? <MenuItem>Loading...</MenuItem> : 
                            errorLoadingAssignees ? <MenuItem>Error</MenuItem> : */}
                            {assigneesData.map((assignee) => (
                                <MenuItem key={assignee.id} value={assignee.name}>{assignee.name}</MenuItem>
                            ))
                        }
                    </Select>
                );
            },
        },
        // parent field
        {
            field: 'parent',
            headerName: 'Parent',
            width: 130,
            valueGetter: (params) => projectsData && getProjectName(params.value),
            renderCell: (params) => (
                <Tooltip title={projectsData && params.value ? params.value : ''} enterDelay={500}>
                    <div>
                        {projectsData && params.value}
                    </div>
                </Tooltip>
            ),
        },
    ];
    const filteredColumns = columns ? taskColumns.filter(column => columns.includes(column.headerName)) : taskColumns;

    // create the handleCloseNewTask function to close the dialog box
    const handleCloseNewTask = () => {
        setOpenAdd(false);
    };

    
    const [newTaskName, setName] = useState('');                           // dialog box form fields
    const [openAdd, setOpenAdd] = useState(false); // useState hook to store and update the open state of the add assignee dialog
    
    const handleAdd = () => {
        setOpenAdd(true);
    };

    return (
        <div className="relative">
            {isLoadingTasksFromApi ? (
                <CircularProgress /> // Display a loading spinner if the tasks are still loading
            ) : errorLoadingFromApi ? (
                <Alert severity="error">Error loading tasks, {errorLoadingFromApi.message}</Alert> // Display an error message if there was an error loading the tasks
            ) : filteredTasksDataDisplayed !== undefined ? (
                <DataGrid

                    rows={filteredTasksDataDisplayed}
                    columns={filteredColumns}
                    checkboxSelection
                    disableRowSelectionOnClick
                    pageSize={filteredTasksDataDisplayed.length}
                    onRowSelectionModelChange={(newSelection) => {
                        setSelectedRows(newSelection);
                    }}
                />
            ) : null}
            {selectedRows.length > 0 && (
                <div className="absolute top-0 right-16 flex space-x-2" style={{ top: '0px' }}>
                    <Tooltip title="Delete selected task(s)">
                        <Fab color="secondary" aria-label="delete" onClick={handleClickOpen} style={{ marginRight: 10 }} className="opacity-50 hover:opacity-100 focus:opacity-100">
                        <DeleteIcon />
                        </Fab>
                    </Tooltip>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you want to delete the selected project(s)?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button onClick={handleDelete} autoFocus>
                                Delete
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            )}

            <div className="absolute right-0 flex space-x-0" style={{ top: '0px' }}>
                <Tooltip title="Add a task">
                    <Fab color="primary" aria-label="add" onClick={handleAdd} style={{ marginRight: 10 }} className="opacity-50 hover:opacity-100 focus:opacity-100">
                        <AddIcon />
                    </Fab>
                </Tooltip>
            </div>
            <Dialog
                open={openAdd}
                onClose={handleCloseNewTask}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Add new task"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Enter the name name of the new task.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Name"
                        type="text"
                        fullWidth
                        value={newTaskName}
                        onChange={(e) => setName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseNewTask}>Cancel</Button>
                    <Button onClick={handleSubmit} autoFocus>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
            >
                <Alert
                    onClose={() => setOpenSnackbar(false)}
                    severity="error"
                    sx={{ width: "100%" }}
                >
                    {dialogMessageSnackBar}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default TasksPrioritizer;